<template>
    <div style="margin: 18px 18px">
        <table>
            <tbody>
                <tr height="40">
                    <td valign="top" colspan="4" class="font-13"></td>
                    <td
                        valign="top"
                        colspan="3"
                        class="text-right font-13 font-bold"
                    >
                        <h3>PAYMENT SCHEDULES</h3>
                    </td>
                </tr>
                <tr>
                    <td width="3%" style="background-color: white;"></td>
                    <td colspan="5" style="background-color: white;">
                        <div>
                            <p>
                                Customer Name:
                                <b class="font-hanuman">{{
                                    loan.customer
                                        ? loan.customer.customer_name_en
                                        : ""
                                }}</b>
                            </p>
                            <p>
                                Customer Code:
                                {{
                                    loan.customer
                                        ? loan.customer.customer_code
                                        : ""
                                }}
                            </p>
                            <p>
                                Phone Number:
                                {{
                                    loan.customer
                                        ? loan.customer.contact_no
                                        : ""
                                }}
                            </p>
                            <p>
                                Address:
                                {{
                                    loan.customer
                                        ? loan.customer.house_no
                                            ? loan.customer.house_no + ", "
                                            : ""
                                        : ""
                                }}
                                {{
                                    loan.customer
                                        ? loan.customer.street_no
                                            ? loan.customer.street_no + ", "
                                            : ""
                                        : ""
                                }}
                                {{
                                    loan.customer
                                        ? loan.customer.vil_name_en + ", "
                                        : ""
                                }}
                                {{
                                    loan.customer
                                        ? loan.customer.com_name_en + ", "
                                        : ""
                                }}
                                {{
                                    loan.customer
                                        ? loan.customer.dis_name_en + ", "
                                        : ""
                                }}
                                {{
                                    loan.customer
                                        ? loan.customer.pro_name_en + "."
                                        : ""
                                }}
                            </p>
                            <p>
                                Credit Officer:
                                {{ loan.co ? loan.co.employee_name_en : "" }}
                                {{ loan.co ? " | " + loan.co.phone : "" }}
                            </p>
                        </div>
                    </td>
                    <td
                        colspan="2"
                        style="background-color: white;white-space: nowrap;"
                    >
                        <div>
                            <p>
                                Loan Number: {{ loan.loan_number }}
                                {{
                                    loan.loan_request
                                        ? loan.loan_request.group_id
                                            ? "Group Number: " + loan.loan_request
                                                  .group_id
                                            : ""
                                        : ""
                                }}
                            </p>
                            <p>
                                Loan Amount:
                                {{
                                    loan.balance_after_reschedule ? formatNumber(
                                        loan.balance_after_reschedule,
                                        loan.currency_id
                                    ) : formatNumber(
                                        loan.loan_amount,
                                        loan.currency_id
                                    )
                                }}
                                {{
                                    loan.currency
                                        ? loan.currency.currency_name_en
                                        : ""
                                }}
                            </p>
                            <p>
                                Loan Product:
                                {{
                                    loan.loan_type
                                        ? loan.loan_type.loan_type_en
                                        : ""
                                }}
                            </p>
                            <p>
                                Number of Cycle: {{ loan.number_of_cycle }} |
                                {{
                                    loan.repayment_method
                                        ? loan.repayment_method.method_name_en
                                        : ""
                                }}
                            </p>
                            <p class="font-12">
                                Disbursement:
                                {{ loan.disbursement_date_en }} Finished:
                                {{ loan.finished_date_en }}
                            </p>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="15" colspan="7"></td>
                </tr>
            </tbody>
        </table>
        <table>
            <thead>
                <tr>
                    <th class="bl br bt bb text-center"></th>
                    <th
                        class="bl br bt bb text-center"
                        colspan="2"
                        valign="top"
                    >
                        Payment Due Date
                    </th>
                    <th class="bl br bt bb text-center" valign="top">
                        Number of Day
                    </th>
                    <th class="bl br bt bb text-center" valign="top">
                        Total Payment
                    </th>
                    <th class="bl br bt bb text-center" valign="top">
                        Recipient's signature
                    </th>
                    <th class="bl br bt bb text-center" valign="top">Other</th>
                </tr>
            </thead>
            <tbody v-if="schedules.length <= 29">
                <tr v-for="(row, index) in schedules" :key="index">
                    <td class="bl br bt bb text-center" width="2%">
                        {{ index + 1 }}
                    </td>
                    <td class="bt bb" width="3%">{{ row.schedule_day_en }}</td>
                    <td class="br bt bb" width="10%">
                        {{ row.schedule_date_en }}
                    </td>
                    <td class="br bt bb text-center" width="10%">
                        {{ row.number_of_day }}
                    </td>
                    <td class="bl br bt bb" width="15%">
                        <div class="flex items-center justify-between">
                            <div class="font-hanuman">
                                {{ loan.currency ? loan.currency.sign : "" }}
                            </div>
                            <div>
                                {{
                                    formatNumber(
                                        row.total_amount,
                                        loan.currency_id
                                    )
                                }}
                            </div>
                        </div>
                    </td>
                    <td class="bl br bt bb" width="15%"></td>
                    <td class="bl br bt bb" width="15%"></td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="(row, index) in schedules.slice(0, chunk)" :key="index">
                    <td class="bl br bt bb text-center" width="2%">
                        {{ index + 1 }}
                    </td>
                    <td class="bt bb" width="3%">{{ row.schedule_day_en }}</td>
                    <td class="br bt bb" width="12%">
                        {{ row.schedule_date_en }}
                    </td>
                    <td class="br bt bb text-center" width="10%">
                        {{ row.number_of_day }}
                    </td>
                    <td class="bl br bt bb" width="15%">
                        <div class="flex items-center justify-between">
                            <div class="font-hanuman">
                                {{ loan.currency ? loan.currency.sign : "" }}
                            </div>
                            <div>
                                {{
                                    formatNumber(
                                        row.total_amount,
                                        loan.currency_id
                                    )
                                }}
                            </div>
                        </div>
                    </td>
                    <td class="bl br bt bb" width="15%"></td>
                    <td class="bl br bt bb" width="15%"></td>
                </tr>
                <div class="html2pdf__page-break"/>
                <tr>
                    <td
                        colspan="7"
                        height="10"
                        style="background-color: white;"
                    ></td>
                </tr>
                <tr>
                    <th class="bl br bt bb text-center"></th>
                    <th
                        class="bl br bt bb text-center"
                        colspan="2"
                        valign="top"
                    >
                        Payment Due Date
                    </th>
                    <th class="bl br bt bb text-center" valign="top">
                        Number of Day
                    </th>
                    <th class="bl br bt bb text-center" valign="top">
                        Total Payment
                    </th>
                    <th class="bl br bt bb text-center" valign="top">
                        Recipient's signature
                    </th>
                    <th class="bl br bt bb text-center" valign="top">Other</th>
                </tr>
                <tr v-for="(row, index ) in schedules.slice(chunk, chunk + schedules.length)" :key="index + 35">
                    <td class="bl br bt bb text-center" width="2%">
                        {{ index + 35 }}
                    </td>
                    <td class="bt bb" width="3%">{{ row.schedule_day_en }}</td>
                    <td class="br bt bb" width="12%">
                        {{ row.schedule_date_en }}
                    </td>
                    <td class="br bt bb text-center" width="10%">
                        {{ row.number_of_day }}
                    </td>
                    <td class="bl br bt bb" width="15%">
                        <div class="flex items-center justify-between">
                            <div class="font-hanuman">
                                {{ loan.currency ? loan.currency.sign : "" }}
                            </div>
                            <div>
                                {{
                                    formatNumber(
                                        row.total_amount,
                                        loan.currency_id
                                    )
                                }}
                            </div>
                        </div>
                    </td>
                    <td class="bl br bt bb" width="15%"></td>
                    <td class="bl br bt bb" width="15%"></td>
                </tr>
                <tr>
                    <td
                        colspan="7"
                        height="10"
                        style="background-color: white;"
                    ></td>
                </tr>
                <tr>
                    <td
                        class="text-center "
                        colspan="4"
                        style="vertical-align: top; background-color: white;"
                    >
                        <b>Creditor Thumbprint Parties("A")</b>
                        <hr
                            width="200"
                            style="margin: auto; margin-top: 70px;"
                        />
                        Date &nbsp;&nbsp;&nbsp;&nbsp;{{
                            loan.disbursement_date_en
                        }}
                    </td>
                    <td style="background-color: white;"></td>
                    <td
                        class="text-center"
                        colspan="2"
                        style="vertical-align: top; background-color: white;"
                    >
                        <b>Debtor's thumbprint Parties("B")</b>
                        <p
                            style="margin-top: 50px; margin-bottom: 5px;"
                            class="font-hanuman"
                        >
                            {{
                                loan.customer
                                    ? loan.customer.customer_name_en
                                    : ""
                            }}
                            |
                            {{
                                loan.customer
                                    ? loan.customer.customer_name_kh
                                    : ""
                            }}
                        </p>
                        <hr width="200" style="margin: auto" />
                        Date &nbsp;&nbsp;&nbsp;&nbsp;{{
                            loan.disbursement_date_en
                        }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { isEmpty } from "lodash";

export default {
    name: "customerRepaymentSchedule",
    props: ["loan"],
    data() {
        return {
            loading: false,
            chunk: 34
        };
    },
    computed: {
        ...mapGetters(["formatNumber"]),
        schedules() {
            if (!isEmpty(this.loan)) {
                return this.loan.repayment_schedules;
            }
            return [];
        }
    }
};
</script>
<style scoped>
@font-face {
    font-family: "trebuchet-ms";
    src: local("trebuchet-ms"),
        url("../../../../assets/fonts/trebuchet-ms.ttf") format("truetype");
}
@font-face {
    font-family: "hanuman";
    src: local("hanuman"),
        url("../../../../assets/fonts/Hanuman.ttf") format("truetype");
}
@font-face {
    font-family: "khmer_moul_light";
    src: local("khmer_moul_light"),
        url("../../../../assets/fonts/KhmerOS_muollight.ttf") format("truetype");
}
* {
    margin: 0;
    padding: 0;
}

table {
    margin: auto;
    width: 100%;
    border-collapse: collapse;
    font-size: 13px;
    font-weight: 400;
    font-family: "trebuchet-ms", "Open Sans";
    border: none;
}

thead {
    display: table-header-group;
    break-inside: avoid;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.bl {
    border-left: 1px dashed #e9e9e9;
}

.br {
    border-right: 1px dashed #e9e9e9;
}

.bt {
    border-top: 1px solid #eeeeee;
}

.bb {
    border-bottom: 1px solid #eeeeee;
}

thead th {
    background-color: #eeeeee;
    padding: 0 1px 6px 1px;
    font-size: 12px;
}

tbody th {
    background-color: #eeeeee;
    padding: 0 1px 6px 1px;
}

tbody td {
    border-collapse: collapse;
    padding: 0 4px 6px 4px;
}

.no-wrap {
    white-space: nowrap;
}

.font-bold {
    font-weight: 700;
}

.font-13 {
    font-size: 13px;
}

.section {
    font-size: 12px;
    font-weight: 700;
}

.flex {
    display: flex;
}

.justify-between {
    justify-content: space-between;
}

.items-center {
    align-items: center;
}

tr:nth-child(even) {
    background-color: #f7f7f7;
}

.font-hanuman {
    font-family: "hanuman";
}
</style>
