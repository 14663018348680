<template>
    <div>
        <Modal
            v-model="show"
            @on-cancel="onCanel"
            draggable
            sticky
            scrollable
            :mask="false"
            :footer-hide="true"
            fullscreen
            :z-index="1022"
            :title="$t('previewPdf')"
        >
            <div class="demo-spin-article">
                <div id="container">
                    <iframe :src="src" v-if="!loading"></iframe>
                </div>
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </Modal>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    name: 'pdfPreview',
    props: ['value', 'idLists', 'type'],
    data () {
        return {
            show: false,
            loading: false,
            src: ''
        }
    },
    methods: {
        ...mapActions('creditOperation/loanDisbursement', ['previewPdf']),
        onCanel () {
            this.src = ''
            this.$emit('cancel', false)
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'LOAN DISBURSEMENT',
                desc: not.text
            })
        }
    },
    watch: {
        value: function (value) {
            if (value) {
                this.show = value
                this.loading = true
                this.previewPdf({
                    loan_id_lists: this.idLists,
                    type: this.type ? this.type : 1,
                })
                    .then(response => {
                        this.src = response.url
                    })
                    .catch(error => {
                        this.notice({ type: 'error', text: error.message })
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        }
    }
}
</script>

<style scoped>
#container {
    width: 100%;
    height: 90vh;
    background: black;
}
#container iframe {
    width: 100%;
    height: 90vh;
}
</style>
